import DeleteAllStepsFromBin from "./DeleteAllStepsFromBin";

export default function ConfirmDeleteAllSteps(props) {

  const { step, steps, setSteps } = props;

  return (
    <div className="center-empty-bin">
      {/* <!-- Button to Open the Modal --> */}
      <button
        type="button"
        className="btn btn-danger"
        data-toggle="modal"
        data-target={`#confirmdeleteallstepsmodal`}
      >
        Empty recycling bin
      </button>

      {/* <!-- The Modal --> */}
      <div className="modal" id={`confirmdeleteallstepsmodal`}>
        <div className="modal-dialog">
          <div className="modal-content">

            {/* <!-- Modal Header --> */}
            <div className="modal-header">
              <h4 className="modal-title">Confirm</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>

            {/* <!-- Modal body --> */}
            <div className="modal-body">
              Steps will be definitely deleted

            </div>

            {/* <!-- Modal footer --> */}
            <div className="modal-footer">
              <DeleteAllStepsFromBin step={step} steps={steps} setSteps={setSteps}/>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
