import StepsIntermediate2 from "./StepsIntermediate2";

export default function Level_Intermediate2({steps, setSteps}) {
  return (
    <section>
      <div className="level-title-smaller">Intermediate 2</div>
      <br />

      <StepsIntermediate2 steps={steps} setSteps={setSteps}/>
    </section>
  )
}