import { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import Searchbar from './Searchbar';

export default function StepsBeginner({steps, setSteps}) {

  
  // const refresh = useRefreshToken();
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController(); //Supported by axios.

    const getSteps = async () => {
      try {
        const response = await axiosPrivate.get('/beginnersteps', {
          signl: controller.signal
        });
        //console.log(response.data);
        isMounted && setSteps(response.data);
      } catch (err) {
        console.error(err)
        navigate('/login', { state: { from: location }, replace: true })
      }
    }

    getSteps();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [
    // axiosPrivate, location, navigate
  ])
  //Array was originally empty but was getting warning. Including this 3 dependencies does not seem to break anything.

  return (
    <>
    <Searchbar steps={steps} setSteps={setSteps}/>
      {steps?.length
        ? (
          <div className='outer-wrapper border-0'>
            <div className="table-wrapper">
          <table>
            <tbody>
              {steps.map((step, i) =>
                <tr key={step._id}>

                  {/* <td className='hide-more-1000 pad-lef-75'><OneStepModal step={step} /></td> */}

                  {/* <td className="hide-less-1000 font-size-22">{step.step_title}</td> */}
                  <td className=" font-size-22"
                  // onClick={navigate('/steps', { state: { from: location }, replace: true })}
                  
                  ><Link to={`/steps/${step._id}`}>{step.step_title}</Link></td>

                  {/* <td className="hide-less-1000"><audio className={styles.audio} src={step.step_pronunciation} controls /></td> */}

      
                  {/* <td className="hide-less-700"><video
                    className='video'
                    title={`${step.step_title} video`}
                    controls="controls"
                    // autoplay="autoplay"
                    height="150px"
                    // loop="loop"
                    preload="auto"
                    width="300px"
                    muted="muted"
                    src={step.step_video}
                  ></video></td> */}

                </tr>
              )}
            </tbody>
          </table>
          </div>
          </div>
        ) : <p
        className='text-align-center'
        >No steps to display</p>}
    </>
  )
}