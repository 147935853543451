import { useState } from "react"
import useAxiosPrivate from '../hooks/useAxiosPrivate';

export default function UploadAudio({setPronunciation, setPronunciationFocus}) {

  const UPLOAD_URL = 'https://www.backend.casinosteps.ca/upload'
  // const UPLOAD_URL = 'http://localhost:3500/upload'
  
  const BACKEND = 'https://www.backend.casinosteps.ca'
  // const BACKEND = 'http://localhost:3500'

  const [success2, setSuccess2] = useState("")
  const [uploaded, setUploaded] = useState(null)

  const axiosPrivate = useAxiosPrivate()
  let myFiles;


  async function handleChange() {
    try {
      myFiles = document.getElementById('myAudioFiles').files
      //console.log("myFiles", myFiles)
      //console.log("my files item 0 file name", myFiles.item(0).name)
      try {
          sendFiles()  
      } catch (err) {
        console.log(err)
      }
    } catch (err) {
      console.log(err)
    }
   
    
  }

  const sendFiles = async (
    // e
    ) => {
    // e.preventDefault();
    setPronunciation(`${BACKEND}/MEDIA/audios/${myFiles.item(0).name}`)
  
    let formData = new FormData()
    formData.append(myFiles.item(0).name, myFiles.item(0))

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      withCredentials: true
    };

    axiosPrivate.post(UPLOAD_URL, formData, {
      onUploadProgress: (data) => {
        setUploaded(Math.round((data.loaded / data.total) * 100));
      }, config
    }
      
      ).then((response) => {
        //console.log(response.data);
        if (response.data.status === "success") {
          setSuccess2(`Uploaded: ${myFiles.item(0).name}`)
        }
      });
    }
   

  return (

    <>

      <div className="upload-form-and-icon">
      {success2 === "" && 
      
          <input
            type="file"
            id="myAudioFiles"
            accept="*.mp3"
            onChange={handleChange}
            aria-describedby="pronunciationnote"
            onFocus={() => setPronunciationFocus(true)}
            onBlur={() => setPronunciationFocus(false)}
          />
        }
                  {uploaded !== 100 && uploaded !== null &&
          <div>
            {`${uploaded}%`}
            </div>
          }
        {/* {success2 === "" && 
        <div className="upload-icon"
          onClick={(e) => sendFiles(e)}
        ><i class="fa-solid fa-cloud-arrow-up"></i></div>
      } */}
      </div>


      <div className="upload-success">{success2}</div>


    </>


  )
}