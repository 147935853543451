
import { useNavigate, useLocation } from 'react-router-dom';

const Admin = ({steps, setSteps}) => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <section className='section-admin'>
            <div className='text-align-center level-title'>Administrator</div>
            <div
            onClick={() => {navigate('/admin/users', { state: { from: location }, replace: true})}}
            className="title-pointer text-align-center margin-top-2em"
            >Manage Users</div> 
            <div
            onClick={() => {navigate('/admin/steps', { state: { from: location }, replace: true})}}
            className="title-pointer text-align-center margin-top-2em"
            >Manage Steps</div>
            <br />
            

        </section>
    )
}

export default Admin


