import useAxiosPrivate from '../hooks/useAxiosPrivate';


export default function RestoreUser ({user, setUsers}) {



const id = user._id
const user_is_active = true;

const axiosPrivate = useAxiosPrivate()

  //URLS

  const RESTORE_URL = `/users/restore`;
  const INACTIVE_USERS_URL = `/users/inactiveusers`;

  //Restore function

  
  const restoreUser = async (e) => {
    e.preventDefault()

    try {
      const body = { id, user_is_active };
// {console.log(body)}
      await axiosPrivate.put(
        RESTORE_URL, body
      )

      axiosPrivate.get(INACTIVE_USERS_URL)
      .then(function (res) {
        setUsers([...res.data]);
      });

      // navigate('/admin', { state: { from: location }, replace: true})

    } catch (err) {
      console.error(err.message)
    }
  }

return (
  <div>
     <button
     className='btn button-black'
     onClick={e => restoreUser(e)}
     >Restore</button>
  </div>
 
)
}