import useAxiosPrivate from '../hooks/useAxiosPrivate';

export default function DeleteStep({ step, steps, setSteps }) {

  const axiosPrivate = useAxiosPrivate()

  //URLS

  const DELETE_URL = '/steps/delete/';

  //Delete function


  function deleteStep(stepId) {

    const headers = {
      'stepId': stepId
    };

    axiosPrivate.delete(DELETE_URL, { headers })
      .then(() => setSteps(steps.filter(step => step._id !== stepId)))
  }

  return (
    <div>
      <button
        type="button"
        className="btn btn-danger"
        data-dismiss="modal"
        onClick={() => deleteStep(step._id)}
      >Confirm delete</button>
    </div>

  )
}