import useAxiosPrivate from '../hooks/useAxiosPrivate';

export default function Restore ({step, setSteps}) {

const id = step._id
const step_is_active = true;

const axiosPrivate = useAxiosPrivate()

  //URLS

  const RESTORE_URL = `/steps/restore`;
  const INACTIVE_STEPS_URL = `/steps/inactive`;

  //Restore function

  
  const restoreStep = async (e) => {
    e.preventDefault()

    try {
      const body = { id, step_is_active };

      await axiosPrivate.put(
        RESTORE_URL, body
      )

      axiosPrivate.get(INACTIVE_STEPS_URL)
      .then(function (res) {
        setSteps([...res.data]);
      });

      // navigate('/admin', { state: { from: location }, replace: true})

    } catch (err) {
      console.error(err.message)
    }
  }

return (
  <div>
     <button
     className='btn button-black'
     onClick={e => restoreStep(e)}
     >Restore</button>
  </div>
 
)
}