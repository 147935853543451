import useAxiosPrivate from '../hooks/useAxiosPrivate';

export default function DeleteAllStepsFromBin({ setSteps }) {

  const axiosPrivate = useAxiosPrivate()

  //URL

  const DELETE_URL = '/steps/delete/all';

  //Delete  function


  const deleteAllInactiveSteps = async () => {
    try {
      axiosPrivate.delete(DELETE_URL, {})
        .then(setSteps([]))
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div>
      <button
        type="button"
        className="btn btn-danger"
        data-dismiss="modal"
        onClick={() => deleteAllInactiveSteps()}
      >Empty recycling bin</button>
    </div>

  )
}