import { useRef, useState, useEffect } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import UploadVideo from "./UploadVideo";
import UploadAudio from "./UploadAudio";

// const TITLE_REGEX = /^[A-Z][A-z0-9-_]{0,50}$/;
const TITLE_REGEX = /^[A-ZÀ-ÿ\u00f1\u00d1 ][0-9-_a-zA-ZÀ-ÿ\u00f1\u00d1 ]{0,50}$/;


const LEVEL_REGEX = /^(1|2|3|4|5|6)$/;
const PRONUNCIATION_REGEX = /^https?:\/\/(www.backend.casinosteps.ca\/MEDIA\/)([A-za-z0-9-_./]{11,120})/; //Does not allow accents
const VIDEO_REGEX = /^https?:\/\/(www.backend.casinosteps.ca\/MEDIA\/)([A-za-z0-9-_./]{11,120})/; //Does not allow accents
const ADDSTEP_URL = '/steps/add';




const StepAdd = () => {

  const levels = [
    {
      level: 1,
      level_title: "Beginner"
    },
    {
      level: 6,
      level_title: "Rueda" //before "Course"
    },
    {
      level: 2,
      level_title: "Intermediate"
    },
    {
      level: 3,
      level_title: "Intermediate2"
    },
    {
      level: 4,
      level_title: "Advanced"
    },
    {
      level: 5,
      level_title: "Advanced2"
    },
  ]


  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate()

  const stepTitleRef = useRef();
  const errRef = useRef();

  const [stepTitle, setStepTitle] = useState('');
  const [validStepTitle, setValidStepTitle] = useState(false);
  const [stepTitleFocus, setStepTitleFocus] = useState(false);

  const [level, setLevel] = useState(1);
  const [validLevel, setValidLevel] = useState(false);
  const [levelFocus, setLevelFocus] = useState(false);

  const [pronunciation, setPronunciation] = useState('');
  const [validPronunciation, setValidPronunciation] = useState(false);
  const [pronunciationFocus, setPronunciationFocus] = useState(false);

  const [video, setVideo] = useState('');
  const [validVideo, setValidVideo] = useState(false);
  const [videoFocus, setVideoFocus] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);


  useEffect(() => {
    stepTitleRef.current.focus();
  }, [])

  useEffect(() => {
    setValidStepTitle(TITLE_REGEX.test(stepTitle));
  }, [stepTitle])

  useEffect(() => {
    setValidLevel(LEVEL_REGEX.test(level));
  }, [level])

  useEffect(() => {
    if (pronunciation === '') { setValidPronunciation(true)} else {
      setValidPronunciation(PRONUNCIATION_REGEX.test(pronunciation));
    }
  }, [pronunciation])

  useEffect(() => {
     if (video === '') { setValidVideo(true)} else {
      setValidVideo(VIDEO_REGEX.test(video));
     }
  }, [video])

  useEffect(() => {
    setErrMsg('');
  }, [stepTitle, level])

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = TITLE_REGEX.test(stepTitle);
    const v2 = LEVEL_REGEX.test(level);
    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      return;
    }

    try {
      //const response = 
      await axiosPrivate.post(ADDSTEP_URL,
        JSON.stringify({ stepTitle, level, pronunciation, video }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      // TODO: remove console.logs before deployment
      // console.log(JSON.stringify(response?.data));
      //console.log(JSON.stringify(response))
      setSuccess(true);
      //clear state and controlled inputs
      setStepTitle('');
      // setLevel('');
      setPronunciation('');
      setVideo('');
      navigate(() => { '/admin/steps' })
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 409) {
        setErrMsg('Title Taken');
      } else {
        setErrMsg('Registration Failed')
      }
      errRef.current.focus();
    }
  }

  const addNewStep = () => {
    setLevel(level)
    setSuccess(false)
  }


  return (
    <>
      {success ? (
        <section>
          <div className="level-title">Step added succesfully</div>
          <br />
          <p>
            {/* <a href="/admin">Go back</a> */}
            <Link to="/admin">Back to list</Link>
            <br />
            <button
              //onClick={() => {navigate('/steps/add', { state: { from: location }, replace: true})}}
              onClick={() => addNewStep()}
            >Add a new step</button>
          </p>
        </section>
      ) : (
        <section>
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <button
            className="close close-one-view"
            onClick={() => { navigate('/admin/steps/', { state: { from: location }, replace: true }) }}
          >&times;</button>
          <div className="level-title">Add Step</div>
          <form onSubmit={handleSubmit}>
            <label htmlFor="stepname">
              Step name:
              <FontAwesomeIcon icon={faCheck} className={validStepTitle ? "valid" : "hide"} />
              <FontAwesomeIcon icon={faTimes} className={validStepTitle || !stepTitle ? "hide" : "invalid"} />
            </label>
            <input
              type="text"
              id={stepTitle}
              autoFocus
              ref={stepTitleRef}
              autoComplete="off"
              onChange={(e) => setStepTitle(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
              value={stepTitle}
              required
              aria-invalid={validStepTitle ? "false" : "true"}
              aria-describedby="uidnote"
              onFocus={() => setStepTitleFocus(true)}
              onBlur={() => setStepTitleFocus(false)}
            />
            <p id="uidnote" className={stepTitleFocus && stepTitle && !validStepTitle ? "instructions" : "offscreen"}>
              <FontAwesomeIcon icon={faInfoCircle} />
              1 to 50 characters.<br />
              Must begin with a capital letter.<br />
              Letters, numbers, underscores, hyphens and spaces allowed.
            </p>


            <label htmlFor="level">
              Level:
              <FontAwesomeIcon icon={faCheck} className={validLevel ? "valid" : "hide"} />
              <FontAwesomeIcon icon={faTimes} className={validLevel || !level ? "hide" : "invalid"} />
            </label>

            <select
              className="form-control"
              // value={level}
              onChange={(e) => setLevel(e.target.value)}
              //
              id={level}
              required
              aria-invalid={validLevel ? "false" : "true"}
              aria-describedby="levelnote"
              onFocus={() => setLevelFocus(true)}
              onBlur={() => setLevelFocus(false)}
            >

              {levels.map((level) =>
                <option
                  key={level.level}
                  value={level.level}
                >
                  {level.level_title}
              

                </option>
              )}

            </select>


            <p id="levelnote" className={levelFocus && !validLevel ? "instructions" : "offscreen"}>
              <FontAwesomeIcon icon={faInfoCircle} />
              Select a level
              <br />
            </p>

            <label htmlFor="video">
              Video:
              <FontAwesomeIcon icon={faCheck} className={validVideo ? "valid" : "hide"} />
              <FontAwesomeIcon icon={faTimes} className={validVideo || !video ? "hide" : "invalid"} />
            </label>
            <UploadVideo setVideoFocus = {setVideoFocus} setVideo = {setVideo}/>

            <p id="videonote" className={videoFocus && !validVideo ? "instructions" : "offscreen"}>
              <FontAwesomeIcon icon={faInfoCircle} />
              File names cannot have accents<br />
            </p>


            <label htmlFor="pronunciation">
              Pronunciation:
              <FontAwesomeIcon icon={faCheck} className={validPronunciation ? "valid" : "hide"} />
              <FontAwesomeIcon icon={faTimes} className={validPronunciation || !pronunciation ? "hide" : "invalid"} />
            </label>
     
            <UploadAudio setPronunciationFocus = {setPronunciationFocus} setPronunciation={setPronunciation}/>

            <p id="pronunciationnote" className={pronunciationFocus && !validPronunciation ? "instructions" : "offscreen"}>
              <FontAwesomeIcon icon={faInfoCircle} />
              File names cannot have accents<br />
            </p>




            <button
              disabled={
                !validStepTitle || !validLevel || !validPronunciation || !validVideo? true : false
              }
            >Add</button>
          </form>

        </section>
      )}
    </>
  )
}

export default StepAdd
