import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

//Components

import UserEdit from "./UserEdit";
import UserEditPassword from "./UserEditPassword";
import CheckboxUsers from "./CheckboxUsers";
import ConfirmRecycleAllSelectedUsers from "./ConfirmRecycleAllSelectedUsers";
import UserEditName from "./UserEditName";
import UserLevelQuickEdit from "./UserLevelQuickEdit";

//Styling
import "./styling/scroll-table.css"
import SearchbarUsers from "./SearchbarUsers";

const julioID = 2;
const manuelID = 1;

const Users = () => {
    const [users, setUsers] = useState();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    //  console.log(users)

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/users', {
                    signal: controller.signal
                });


                // console.log("res data", response.data);
                // const userNames = response.data.map(user => user.username)
                // const userIds = response.data.map(user => user._id)


                //console.log(response.data); //Comment out production
                isMounted && setUsers(response.data);
            } catch (err) {
                console.error(err);
                navigate('/login', { state: { from: location }, replace: true });
            }
        }

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate])
    //Array was originally empty but was getting warning. Including this 3 dependencies does not seem to break anything.

    //   function deleteUser(userId) {
    //     // console.log("user id", userId)
    //     const headers = {
    //       'userId': userId
    //     };

    //     axiosPrivate.delete('/users/delete/', { headers })
    //       .then(() => setUsers(users.filter(user => user._id !== userId)))
    //   }

    return (
        <section className="section-bigger section-admin">
            <div
                onClick={() => { navigate('/admin', { state: { from: location }, replace: true }) }}
                className="title-pointer text-align-center level-title"
            >Administrator</div>
            <div>Manage Users</div>
<div className="show-less-500">This section works better in wider screens. <br></br>Rotate your device for a better view. </div>

            {/* <div className="delete-selection-recycling-bin">
                <ConfirmRecycleAllSelectedUsers setUsers={setUsers} />
                {console.log(users)}
            </div> */}

<SearchbarUsers setUsers={setUsers}/>

            {users?.length
                ? (

                    
                        <div className='outer-wrapper'>

                            <div className="table-wrapper">

                                <div className="delete-selection-recycling-bin">
                                    <ConfirmRecycleAllSelectedUsers setUsers={setUsers} />
                                    <button
                                        className="delete-selection btn-no-btn"
                                        title="Go to recycling bin"
                                        onClick={() => { navigate('/users/recycling', { state: { from: location }, replace: true }) }}><i className="fa-solid fa-trash-can"></i></button>

                                </div>
                                <table>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Level</th>
                                        <th>Email</th>
                                        <th>Password</th>
                                        <th>Contact</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                        {users.map((user) =>

                                            <tr key={user._id}>
                                                <td>{user._id !== julioID && user._id !== manuelID && <CheckboxUsers user={user} setUsers={setUsers} />}</td>
                                                <td>{
                                                    <UserEditName user={user} users={users} setUsers={setUsers} />}</td>
                                                <td><UserLevelQuickEdit user={user} users={users} setUsers={setUsers} /></td>
                                                <td>{<UserEdit user={user} users={users} setUsers={setUsers} />}</td>

                                                <td><UserEditPassword user={user} users={users} setUsers={setUsers} /></td>
                                                <td>

                                                    <a href={`mailto:${user.email}`}>
                                                        <button
                                                            className="btn-no-btn"
                                                            title="Send email"
                                                        ><i className="fa-solid fa-envelope"></i></button>
                                                    </a>
                                                </td>
                                            </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                       

                ) : <p>No users to display</p>
            }
        </section>
    );
};

export default Users;
