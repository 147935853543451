import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react';
import useLogout from "../hooks/useLogout";
import useAuth from '../hooks/useAuth';

// import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import '../css/navbar.css'

export default function Navbar() {

  const { auth } = useAuth()
  // console.log('auth', auth)

  const userId = auth.userId;

  const [show, setShow] = useState(true)

  const logout = useLogout();
  const navigate = useNavigate();

  const signOut = async () => {
    await logout();
    navigate('/');
  }

  const toggle = () => {
    setShow(!show)
  }


  return (
    <div className='bars-plus-navbar'>

      <div className='bars navbar-link'>
        {/* <button onClick={setShow(false)}>bars</button> */}
        {/* <div onClick={toggle}
          className='navbar-div no-decoration'
        ><i className="fa-solid fa-bars"></i></div> */}
      </div>

      {show && (

        <div className='sidenav'>

          <Link title="Home" className='navbar-link' to="/"><i className="fas fa-home-alt nav-link-icon"></i></Link>
          <Link className='navbar-link' to="/beginner" title="Beginner">
            {/* <i className="fa-solid fa-b"></i> */}
            B
          </Link>
          <Link className='navbar-link' to="/course" title="Course">
            {/* <i className="fa-solid fa-i"></i> */}
            R
          </Link>
          <Link className='navbar-link' to="/intermediate" title="Intermediate">
            {/* <i className="fa-solid fa-i"></i> */}
            I
          </Link>
          <Link className='navbar-link' to="/intermediate2" title="Intermediate 2">
            {/* <i className="fa-solid fa-2"></i> */}
            I2
          </Link>
          <Link className='navbar-link' to="/advanced" title="Advanced">
            {/* <i className="fa-solid fa-a"></i> */}
            A
          </Link>
          <Link className='navbar-link' to="/advanced2" title="Advanced2">
            A2
          </Link>
          {/* {auth.userId && (
            <Link className='navbar-link' to={`/users/${userId}`} title="Profile"><i className="fa-solid fa-user"></i></Link>
          )} */}
          <Link className='navbar-link' to="/register" title="Register"><i className="fa-solid fa-user-plus"></i></Link>
          {Object.keys(auth).length
            ? <Link className='navbar-link' to="#" title="Sign Out" onClick={signOut}> <i className="fa-solid fa-arrow-right-from-bracket nav-link-icon"></i></Link>
            : <Link className='navbar-link' to="/login" title="Login"> <i className="fa-solid fa-arrow-right-to-bracket nav-link-icon"></i> </Link>
          }


          {Object.keys(auth).length && Object.values(auth.roles).pop() === 2006
            ? <Link className='navbar-link' to="/admin" title="Administrator"><i className="fa-solid fa-gear"></i> </Link>
            : <></>
          }
        </div>



      )}


      {show && (


        <div className='navbar-small'>
          <Link title="Home" className='navbar-link' to="/">
            <i className="fas fa-home-alt nav-link-icon"></i>
          </Link>
          <Link className='navbar-link' to="/beginner" title="Beginner">
            {/* <i className="fa-solid fa-b"></i> */}
            B
          </Link>
          <Link className='navbar-link' to="/course" title="Course">
            {/* <i className="fa-solid fa-i"></i> */}
            R
          </Link>
          <Link className='navbar-link' to="/intermediate" title="Intermediate">
            {/* <i className="fa-solid fa-i"></i> */}
            I
          </Link>
          <Link className='navbar-link' to="/intermediate2" title="Intermediate 2">
            {/* <i className="fa-solid fa-2"></i> */}
            I2
          </Link>
          <Link className='navbar-link' to="/advanced" title="Advanced">
            {/* <i className="fa-solid fa-a"></i> */}
            A
          </Link>
          <Link className='navbar-link' to="/advanced2" title="Advanced2">
            A2
          </Link>
          {/* {auth.userId && (
            <Link className='navbar-link' to={`/users/${userId}`} title="Profile"><i className="fa-solid fa-user"></i></Link>
          )} */}
          <Link className='navbar-link' to="/register" title="Register">
            <i className="fa-solid fa-user-plus"></i></Link>
          {Object.keys(auth).length
            ? <Link className='navbar-link' to="#" title="Sign Out" onClick={signOut}> <i className="fa-solid fa-arrow-right-from-bracket nav-link-icon"></i></Link>
            : <Link className='navbar-link' to="/login" title="Login"> <i className="fa-solid fa-arrow-right-to-bracket nav-link-icon"></i> </Link>
          }


          {Object.keys(auth).length && Object.values(auth.roles).pop() === 2006
            ? <Link className='navbar-link' to="/admin" title="Administrator"><i className="fa-solid fa-gear"></i> </Link>
            : <></>
          }



        </div>


      )}

      {show && (

        <div className='navbar-big'>
          <Link className='navbar-link' to="/">Home</Link>
          <Link className='navbar-link' to="/beginner">Beginner</Link>
          <Link className='navbar-link' to="/course">Rueda</Link>
          <Link className='navbar-link' to="/intermediate">Intermediate</Link>
          <Link className='navbar-link' to="/intermediate2">Intermediate2</Link>
          <Link className='navbar-link' to="/advanced">Advanced</Link>
          <Link className='navbar-link' to="/advanced2">Advanced2</Link>
          {/* {auth.userId && (
            <Link className='navbar-link' to={`/users/${userId}`}>Profile</Link>
          )} */}
          <Link className='navbar-link' to="/register">Register</Link>
          {Object.keys(auth).length
            ? <Link className='navbar-link' to="#" onClick={signOut}>Sign Out</Link>
            : <Link className='navbar-link' to="/login">Login</Link>
          }

          {Object.keys(auth).length && Object.values(auth.roles).pop() === 2006
            ? <Link className='navbar-link' to="/admin">Admin</Link>
            : <></>
          }

          {/* <Link className='navbar-link' to="/admin">Admin</Link> */}
        </div>



      )}



    </div>
  )
}











