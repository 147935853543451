import StepsIntermediate from "./StepsIntermediate";

export default function Level_Intermediate({steps, setSteps}) {
  return (
    <section>
      <div className="level-title">Intermediate</div>
      <br />
      
      <StepsIntermediate steps={steps} setSteps={setSteps}/>
    </section>
  )
}