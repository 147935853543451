import useAxiosPrivate from '../hooks/useAxiosPrivate';

export default function DeleteUser({ user, users, setUsers }) {

  const axiosPrivate = useAxiosPrivate()

  //URLS

  const DELETE_URL = '/users/delete/';

  //Delete function


  function deleteUser(userId) {

    const headers = {
      'userId': userId
    };

    axiosPrivate.delete(DELETE_URL, { headers })
      .then(() => setUsers(users.filter(user => user._id !== userId)))
  }

  return (
    <div>
      <button
        type="button"
        className="btn btn-danger"
        data-dismiss="modal"
        onClick={() => deleteUser(user._id)}
      >Confirm delete</button>
    </div>

  )
}