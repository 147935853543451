import { useState } from "react"
import axios from 'axios';

export default function FileUpload() {



  const [fileState, setFileState] = useState(null)

  const handleFile = (e) => {
    let file = e.target.files[0]

    setFileState(file)

  }

  const handleUpload = (e) => {

    let formdata = new FormData()

    let file = fileState;
    console.log("file", file)

    formdata.append('image', file);
    formdata.append('name', "Manuel");



    axios({
      url: 'https://www.backend.casinosteps.ca/upload',
      // url: 'http://localhost:3500/upload',
      method: 'POST',
      headers: {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      },
      data: formdata

    }).then((res) => {
      console.log(res)
    })

  }

  return (
    <div className="upload-file">
      <h4>File upload</h4>

      <form>

        <div className="">
          <label>Select file</label>
          <input type="file" name="file" onChange={(e) => handleFile(e)} />
        </div>

        <button type="button" onClick={(e) => handleUpload(e)}>Upload</button>

      </form>

    </div>

  )
}