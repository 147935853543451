import StepsCourse from "./StepsCourse";

export default function Level_Course({steps, setSteps}) {
  return (
    <section>
      <div className="level-title">Rueda</div>
      <br />
      
      <StepsCourse steps={steps} setSteps={setSteps}/>
    </section>
  )
}