import { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import styles from "./Songs/styles.module.css";

//Components
import StepEdit from './StepEdit';
// import ConfirmRecycle from './ConfirmRecycle';
import CheckboxSteps from './CheckboxSteps';
import ConfirmRecycleAllSelectedSteps from './ConfirmRecycleAllSelectedSteps';
import OneStepModal from './OneStepModal';
import OneStepView from './OneStepView';
import Searchbar from './Searchbar';


export default function Steps({ steps, setSteps }) {

  // const refresh = useRefreshToken();
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate();
  const location = useLocation();

  const [modalOpen, setModalOpen] = useState(false);
  // console.log(modalOpen)
  // function deleteStep(stepId) {
  //   const headers = {
  //     'stepId': stepId
  //   };

  //   axiosPrivate.delete('/steps/delete/', { headers })
  //     .then(() => setSteps(steps.filter(step => step._id !== stepId)))
  // }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController(); //Supported by axios.

    const getSteps = async () => {
      try {
        const response = await axiosPrivate.get('/steps', {
          signal: controller.signal
        });
        //console.log(response.data);
        isMounted && setSteps(response.data);
      } catch (err) {
        console.error(err)
        navigate('/login', { state: { from: location }, replace: true })
      }
    }

    getSteps();
    // console.log(steps)

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [
    axiosPrivate, location, navigate
  ])

  useEffect(() => {
    setSteps(steps)
  }, [steps])
  //Array was originally empty but was getting warning. Including this 3 dependencies does not seem to break anything.

  return (
    <>
    
    <section className="section-bigger section-admin">
      <br />
      <div
        onClick={() => { navigate('/admin', { state: { from: location }, replace: true }) }}
        className="title-pointer text-align-center level-title"
      >Administrator</div>

      <div>Manage steps</div>
      {/* <div className="show-less-500">This section works better in wider screens. <br></br>Rotate your device for a better view. </div> */}

      <button
        className="add-step-button"
        onClick={() => { navigate('/steps/add', { state: { from: location }, replace: true }) }}
      >Add Step</button>


      <div className="delete-selection-recycling-bin">
        <ConfirmRecycleAllSelectedSteps setSteps={setSteps} />
        <button
          className="delete-selection btn-no-btn"
          title="Go to recycling bin"
          onClick={() => { navigate('/steps/recycling', { state: { from: location }, replace: true }) }}><i className="fa-solid fa-trash-can"></i></button>
      </div>

      <Searchbar setSteps={setSteps}/>

      {steps?.length
        ? (
          <div className='admin-items-group'>
            {steps.map((step, i) =>

               <div key={step._id}>
              <div className='admin-items'>
                <div><CheckboxSteps step={step} setSteps={setSteps} /></div>
                <div className=" font-size-22 admin-items-name"><Link to={`/steps/${step._id}`}>{step.step_title}</Link></div>
              </div>
              </div>
            )}
            {/* {console.log(steps)} */}
          </div>

        ) : <p
          className='text-align-center'
        >No steps to display</p>}
    </section>
    </>
  )
}


// {steps?.length
//   ? (
//     <>
//       {steps.map((step, i) =>
//         // <tr key={step._id}>
//         <div className='admin-items'>
//           <div><CheckboxSteps step={step} setSteps={setSteps} /></div>
//           <div className=" font-size-22 admin-items-name"><Link to={`/onestepview/${step._id}`}>{step.step_title}</Link></div>
//         </div>
//       )}
//     </>
//   ) : <p
//     className='text-align-center'
//   >No steps to display</p>}

// {steps?.length
//   ? (

//     <table>
//       <tbody>
//         {steps.map((step, i) =>
//           <tr key={step._id}>
//             <td className='admin-items'>
//               <div><CheckboxSteps step={step} setSteps={setSteps} /></div>
//               <td className=" font-size-22 admin-items-name"><Link to={`/onestepview/${step._id}`}>{step.step_title}</Link></td>
//             </td>
//           </tr>
//         )}
//       </tbody>

//     </table>
//   ) : <p
//     className='text-align-center'
//   >No steps to display</p>}