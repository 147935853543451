import { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import RestoreUsers from './RestoreUser'
import ConfirmDeleteUser from './ConfirmDeleteUser';
import ConfirmDeleteAllUsers from "./ConfirmDeleteAllUsers";


export default function RecyclingUsers() {

  const [users, setUsers] = useState();
  // const refresh = useRefreshToken();
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController(); //Supported by axios.

    const getInactiveUsers = async () => {
      try {
        const response = await axiosPrivate.get('/users/inactiveusers', {
          signal: controller.signal
        });
        //console.log(response.data);
        isMounted && setUsers(response.data);
      } catch (err) {
        console.error(err)
        navigate('/login', { state: { from: location }, replace: true })
      }
    }

    getInactiveUsers();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [
    axiosPrivate, location, navigate
  ])
  //Array was originally empty but was getting warning. Including this 3 dependencies does not seem to break anything.

  return (
    <section className="section-one-step-view">
      <br />
      <div
        className='text-align-center level-title'
      >Recycling bin</div>

      <Link to="/admin/users" className='text-align-center'>Back to list</Link>

      <ConfirmDeleteAllUsers users={users} setUsers={setUsers} />
      <br />
      {/* <button onClick={
  navigate('/steps/recycling', { state: { from: location }, replace: true })
}>Recycling bin</button> */}

      {/* {console.log(users)} */}

      {users?.length
        ? (
          <div className='outer-wrapper'>
            <div className="table-wrapper">
              <table>
                <tbody>
                  {users.map((user, i) =>
                    <tr key={user._id}>
                      <td className='rec-bin-item'>{user?.username}</td>
                      <td><ConfirmDeleteUser user={user} users={users} setUsers={setUsers} /></td>
                      <td><RestoreUsers user={user} setUsers={setUsers} /></td>
                    </tr>

                  )}
                </tbody>

              </table>
            </div>
          </div>

        ) : <p
        className='text-align-center'
        >No users to display</p>}

      {/* <button onClick={() => refresh()}>Refresh</button>
        <br /> */}
    </section>
  )
}