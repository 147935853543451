
import DeleteAllUsersFromBin from "./DeleteAllUsersFromBin";

export default function ConfirmDeleteAllUsers(props) {

  const { user, users, setUsers } = props;

  return (
    <div className="center-empty-bin">
      {/* <!-- Button to Open the Modal --> */}
      <button
        type="button"
        className="btn btn-danger"
        data-toggle="modal"
        data-target={`#confirmdeleteallusersmodal`}
      >
        Empty recycling bin
      </button>

      {/* <!-- The Modal --> */}
      <div className="modal" id={`confirmdeleteallusersmodal`}>
        <div className="modal-dialog">
          <div className="modal-content">

            {/* <!-- Modal Header --> */}
            <div className="modal-header">
              <h4 className="modal-title">Confirm</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>

            {/* <!-- Modal body --> */}
            <div className="modal-body">
              Users will be definitely deleted

            </div>

            {/* <!-- Modal footer --> */}
            <div className="modal-footer">
              <DeleteAllUsersFromBin user={user} users={users} setUsers={setUsers}/>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
