import StepsAdvanced from "./StepsAdvanced";

export default function Level_Advanced({steps, setSteps}) {
  return (
    <section>
      <div className="level-title">Advanced</div>
      <br />
      <StepsAdvanced steps={steps} setSteps={setSteps}/>
    </section>
  )
}