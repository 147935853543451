import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useState } from 'react';

export default function CheckboxSteps({ step, setSteps }) {

const id = step._id;



//URLS
const HANDLESELECT_URL = 'steps/handleselect';
const ACTIVE_STEPS_URL = `/steps/`;


//Axios
const axiosPrivate = useAxiosPrivate()

//State
const [selected, setSelected] = useState(true)

  const handleSelect = async () => {
    setSelected(old => !old)
    const body = {id, selected}
    try {
      await axiosPrivate.put(
        HANDLESELECT_URL, body 
      )
      axiosPrivate.get(ACTIVE_STEPS_URL).then

      (function (res) {
        setSteps([...res.data]);

      });
    } catch (err) {
      console.error(err)
    }
  }



  return (
    <input
    title="Select to delete"
      type='checkbox'
      value={selected}
      // onClick={() => { step.step_is_selected === false ? select() : unselect() }}
      onClick={() => {handleSelect() }}
    ></input>
  )
}