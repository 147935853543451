import DeleteAllStepsFromBin from "./DeleteAllStepsFromBin";
import RecycleAllSelectedUsers from "./RecycleAllSelectedUsers";

export default function ConfirmRecycleAllSelectedUsers(props) {

  const { user, users, setUsers } = props;

  return (
    <div>
      {/* <!-- Button to Open the Modal --> */}
      <button
        type="button"
        title="Delete selected"
        className="delete-selection btn-no-btn"
        data-toggle="modal"
        data-target={`#confirmrecycleallselectedusersmodal`}
      >
                {/* <i className="fa-solid fa-trash-can-arrow-up"></i> */}
Delete selected
      </button>

      {/* <!-- The Modal --> */}
      <div className="modal" id={`confirmrecycleallselectedusersmodal`}>
        <div className="modal-dialog">
          <div className="modal-content">

            {/* <!-- Modal Header --> */}
            <div className="modal-header">
              <h4 className="modal-title">Confirm</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>

            {/* <!-- Modal body --> */}
            <div className="modal-body">
            Send the selected users to the recycling bin?

            </div>

            {/* <!-- Modal footer --> */}
            <div className="modal-footer">

              <RecycleAllSelectedUsers setUsers={setUsers}/>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
