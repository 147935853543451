import { useState } from "react";
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useNavigate, useParams } from "react-router-dom";

export default function SearchbarUsers({ setUsers}) {

  const [input, setInput] = useState("");
  const axiosPrivate = useAxiosPrivate()


  const onSearch = function (event) {
    event.preventDefault();

    let SEARCH_URL = `users/search/?searchInput=${input}`;

    axiosPrivate.post(SEARCH_URL).then(function (res) {
      setUsers([...res.data]);
      resetForm();
      // navigate("/");
    });
  };

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.key === "Enter") {
      onSearch(event);
    }
  };

  function resetForm() {
    setInput("");
  }

  return (
    <div className="searchbar">
      <input
        className="searchbar-text"
        type="text"
        value={input}
        placeholder="Search"
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={handleKeypress}
      ></input>

      <button className="searchbar-search-button" onClick={onSearch}>

        <i className="fa-solid fa-magnifying-glass"></i>

      </button>
    </div>
  );
}