import { useState } from "react";
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useNavigate, useParams } from "react-router-dom";

export default function Searchbar({steps, setSteps}) {

  const [input, setInput] = useState("");
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate()

  const levelParam = useParams();
  const stringifyLevel = JSON.stringify(levelParam);
  const lev = stringifyLevel.slice(6)
  const level = lev.slice(0, -2);
  // console.log("level", level)

  let level_id = {level: ""}

  if (level === 'beginner') {
    level_id = {level: 1};
  } else if (level === 'intermediate') {
    level_id = {level: 2};
  } else if (level === 'intermediate2') {
    level_id = {level: 3};
  } else if (level === 'advanced') {
    level_id = {level: 4};
  } else if (level === 'advance2') {
    level_id = {level: 5};
  } else if (level === 'admin/steps') {
    level_id = {level: 99}
  } else if (level === 'admin/users') {
    level_id = {level: 99}
  }

  const onSearch = function (event) {
    event.preventDefault();

    let SEARCH_URL = `steps/search/?searchInput=${input}`;

    axiosPrivate.post(SEARCH_URL, JSON.stringify({level_id})).then(function (res) {
      setSteps([...res.data]);
      resetForm();
      // navigate("/");
    });
  };

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.key === "Enter") {
      onSearch(event);
    }
  };

  function resetForm() {
    setInput("");
  }

  return (
    <div className="searchbar">
      <input
        className="searchbar-text"
        type="text"
        value={input}
        placeholder="Search"
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={handleKeypress}
      ></input>

      <button className="searchbar-search-button" onClick={onSearch}>
 
      <i className="fa-solid fa-magnifying-glass"></i>

      </button>
    </div>
  );
}
