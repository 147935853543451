import StepsBeginner from "./StepsBeginner";

export default function LevelBeginner({steps, setSteps}) {
  return (
    <section>
      <div className="level-title">Beginner</div>
      <br />
      <StepsBeginner steps={steps} setSteps={setSteps}/>

    </section>
  )
}