import { useState } from "react"
import useAxiosPrivate from '../hooks/useAxiosPrivate';

export default function UploadVideo({ setVideo, setVideoFocus }) {

  const UPLOAD_URL = 'https://www.backend.casinosteps.ca/upload'
  const BACKEND = 'https://www.backend.casinosteps.ca'

  // const UPLOAD_URL = 'http://localhost:3500/upload'
  // const BACKEND = 'http://localhost:3500'

  const [success, setSuccess] = useState("")
  const [uploaded, setUploaded] = useState(null)

  const axiosPrivate = useAxiosPrivate()
  let myFiles;


  async function handleChange() {
    try {
      myFiles = document.getElementById('myVideoFile').files
      //console.log("myFiles", myFiles)
      try {
        sendFiles()
      } catch (err) {
        console.log(err)
      }
    } catch (err) {
      console.log(err)
    }

  }

  const sendFiles = async (
    // e
  ) => {
    //e.preventDefault();
    setVideo(`${BACKEND}/MEDIA/videos/${myFiles.item(0).name}`)

    let formDataVideo = new FormData()
    formDataVideo.append(myFiles.item(0).name, myFiles.item(0))

    for (var key of formDataVideo.entries()) {
      //console.log(key[0] + ', ' + key[1]);
      //console.log("type of key", typeof key[0], typeof key[1])
    }

    //  console.log("form data apparently cannot get other than empty", formDataVideo)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      withCredentials: true
    };

    axiosPrivate.post(UPLOAD_URL, formDataVideo, {
      onUploadProgress: (data) => {
        setUploaded(Math.round((data.loaded / data.total) * 100));
      }, config
    }

    ).then((response) => {
      //console.log(response.data);
      if (response.data.status === "success") {
        setSuccess(`Uploaded: ${myFiles.item(0).name}`)
      }
    });
  }


  return (

    <>

      <div className="upload-form-and-icon">
        {success === "" &&

          <input
            type="file"
            id="myVideoFile"
            accept="video/*"
            onChange={handleChange}
            aria-describedby="videonote"
            onFocus={() => setVideoFocus(true)}
            onBlur={() => setVideoFocus(false)}
          />

        }

        {uploaded !== 100 && uploaded !== null &&
          <div>
            {`${uploaded}%`}
          </div>
        }

        {/* {success === "" &&
          <div className="upload-icon"
            onClick={(e) => sendFiles(e)}
          ><i class="fa-solid fa-cloud-arrow-up"></i></div>
        } */}
      </div>


      <div className="upload-success">{success}</div>


    </>


  )
}