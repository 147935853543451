import { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import Restore from './RestoreStep'
import ConfirmDelete from './ConfirmDelete';
import ConfirmDeleteAllSteps from './ConfirmDeleteAllSteps';

export default function RecyclingSteps() {

  const [steps, setSteps] = useState();
  // const refresh = useRefreshToken();
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController(); //Supported by axios.

    const getInactiveSteps = async () => {
      try {
        const response = await axiosPrivate.get('/steps/inactive', {
          signal: controller.signal
        });
        //console.log(response.data);
        isMounted && setSteps(response.data);
      } catch (err) {
        console.error(err)
        navigate('/login', { state: { from: location }, replace: true })
      }
    }

    getInactiveSteps();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [
    axiosPrivate, location, navigate
  ])
  //Array was originally empty but was getting warning. Including this 3 dependencies does not seem to break anything.

  return (
    <section className="section-one-step-view">
      <br />
      <div
      className='text-align-center level-title'
      >Recycling bin</div>
      
      <Link to="/admin/steps"
      className='text-align-center'
      >Back to list</Link>
  
      <ConfirmDeleteAllSteps steps={steps} setSteps={setSteps}/>
      <br/>
{/* <button onClick={
  navigate('/steps/recycling', { state: { from: location }, replace: true })
}>Recycling bin</button> */}

{/* {console.log(steps)} */}

      {steps?.length
        ? (
          <div className='outer-wrapper'>
            <div className="table-wrapper">
          <table>
<tbody>
            {steps.map((step, i) =>
              <tr key={step._id}>
                <td className='rec-bin-item'>{step?.step_title}</td>
                <td><ConfirmDelete step={step} steps={steps} setSteps={setSteps}/></td>
                <td><Restore step={step} setSteps={setSteps}/></td>
              </tr>

            )}
          </tbody>

          </table>
          </div>
          </div>
          
        ) : <p
        className='text-align-center'
        >No steps to display</p>}

      {/* <button onClick={() => refresh()}>Refresh</button>
        <br /> */}
    </section>
  )
}