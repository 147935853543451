import useAxiosPrivate from '../hooks/useAxiosPrivate';

export default function RecycleAllSelectedSteps({ step, setSteps }) {



  const axiosPrivate = useAxiosPrivate()

  //URLS

  const RECYCLEALL_URL = `/steps/recycleselected`;
  const ACTIVE_STEPS_URL = `/steps/`; //HERE

  //Recycle function


  const recycleSelectedSteps = async (e) => {
    e.preventDefault()

    try {
      await axiosPrivate.put(
        RECYCLEALL_URL
      )
      axiosPrivate.get(ACTIVE_STEPS_URL).then

        (function (res) {
          setSteps([...res.data]);

        });
    } catch (err) {
      console.error(err.message)
    }
  }

  return (
    <div>
      <button
        type="button"
        className="btn button-black"
        data-dismiss="modal"
        onClick={e => recycleSelectedSteps(e)}
      >Yes</button>
    </div>

  )
}